$(document).on("upload:start", ".edit_medium, .new_medium", function (e) {
  $(this).find("input[type=submit]").attr("disabled", true);
});

$(document).on("upload:complete", ".edit_medium, .new_medium", function (e) {
  if (!$(this).find("input.uploading").length) {
    $(this).find("input[type=submit]").removeAttr("disabled");
  }
  var url = JSON.parse(e.originalEvent.detail.xhr.response).url;
  $(this).find("#uploaded_file").attr("src", url);
});

$("input[type=file]").on("change", (ie) => {
  const file = ie.target.files[0];
  if (!file) return;
  const reader = new FileReader();
  reader.onload = (re) => {
    let img = new Image();
    img.src = re.target.result;
    const reader_width = $("#uploaded_file").width();
    const reader_height = $("#uploaded_file").height();

    if (img.width > img.height) {
      $("#uploaded_file").attr("src", re.target.result).width(reader_width);
    } else {
      $("#uploaded_file").attr("src", re.target.result).height(reader_height);
    }
  };
  reader.readAsDataURL(file);
});

$(document).on("input", "#media-search", function (e) {
  var needle = RegExp(".*" + $(this).val() + ".*", "i");
  $("#media-list")
    .children()
    .show()
    .filter(function () {
      var haystack = $(this).data("name");
      return !needle.test(haystack); // Use test() method to check for a match
    })
    .hide();
});
