$(function () {
  $("table.opening-hours").on("click", ".preview_link", function (event) {
    var url = $(this).attr("href");

    window.open(
      url,
      "preview",
      "toolbar=no,scrollbars=no,resizable=no,top=500,left=500,width=306,height=595"
    );
    event.preventDefault();
  });

  var hash = window.location.hash.substr(1);
  if (hash.length > 0) {
    $("#address_filter").val(hash);
    filter_opening_hours_table();
  }

  $("#address_filter").on("change keydown paste input", function (event) {
    filter_opening_hours_table();
  });

  $("#city_filter").on("change keydown paste input", function (event) {
    filter_opening_hours_table();
  });
});

function filter_opening_hours_table() {
  var address = $("#address_filter").val().toLowerCase();
  var city = $("#city_filter").val().toLowerCase();

  var visibleindex = 0;

  $("tr.editable").each(function (index) {
    var address_show =
      address == "" || $(this).data("address").includes(address);
    var city_show = city == "" || $(this).data("city").includes(city);

    if (address_show && city_show) {
      $(this).show();
      visibleindex += 1;
      if (visibleindex % 2) {
        $(this).addClass("visible-odd").removeClass("visible-even");
      } else {
        $(this).addClass("visible-even").removeClass("visible-odd");
      }
    } else {
      $(this).hide();
    }
  });
}