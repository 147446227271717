import jQuery from "jquery";
import Rails from "@rails/ujs";
import JSZip from "jszip";

Object.assign(window, {
  $: jQuery,
  jQuery,
  Rails,
  JSZip,
});

import Bloodhound from "bloodhound-js";
import * as Typeahead from "typeahead.js";

Object.assign(window, { Bloodhound, Typeahead });
try {
  Rails.start();
} catch {}
