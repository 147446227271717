$(function () {
  $("table.weather-forecast").on("click", ".preview_link", function (event) {
    var url = $(this).attr("href");

    window.open(
      url,
      "preview",
      "toolbar=no,scrollbars=no,resizable=no,top=500,left=500,width=550,height=420"
    );

    event.preventDefault();
  });
});
