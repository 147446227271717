document.addEventListener("DOMContentLoaded", function () {
  function setupRadioHandlers() {
    var radioButtons = document.querySelectorAll(
      'input[name="dyn_spot[filter_type]"]'
    );
    var branchList = document.getElementById("branch-list-container");
    var gfCategoryList = document.getElementById("category-list-container");

    function updateListVisibility() {
      var selectedRadio = document.querySelector(
        'input[name="dyn_spot[filter_type]"]:checked'
      );
      if (selectedRadio && selectedRadio.value === "by_branch") {
        branchList.style.display = "block";
        gfCategoryList.style.display = "none";
      } else if (selectedRadio && selectedRadio.value === "by_category") {
        branchList.style.display = "none";
        gfCategoryList.style.display = "block";
      }
    }

    radioButtons.forEach(function (radio) {
      radio.addEventListener("change", function () {
        updateListVisibility();
      });
    });

    updateListVisibility();
  }

  function setupCheckboxHandlers(searchInputId, listId) {
    var searchInput = document.getElementById(searchInputId);
    var list = document.getElementById(listId);
    var checkboxes = list.getElementsByClassName("checkbox");
    var selectAllCheckbox = list.querySelector("#select-all");

    function updateSelectAllCheckboxState() {
      var visibleCheckboxes = Array.from(checkboxes).filter(function (
        checkbox
      ) {
        return checkbox.style.display !== "none";
      });

      var allChecked = visibleCheckboxes.every(function (checkbox) {
        return checkbox.getElementsByTagName("input")[0].checked;
      });

      selectAllCheckbox.checked = allChecked;
    }

    searchInput.addEventListener("input", function () {
      var filter = searchInput.value.toLowerCase();

      // Filter checkboxes based on search input
      Array.from(checkboxes).forEach(function (checkbox) {
        var label = checkbox.getElementsByTagName("label")[0];
        var text = label.innerText.toLowerCase();

        if (text.includes(filter)) {
          checkbox.style.display = "";
        } else {
          checkbox.style.display = "none";
        }
      });

      // Update the "Select All" checkbox state based on the current filter
      updateSelectAllCheckboxState();
    });

    // Select or deselect all checkboxes
    selectAllCheckbox.addEventListener("change", function () {
      var isChecked = selectAllCheckbox.checked;

      // Apply to only visible checkboxes
      Array.from(checkboxes).forEach(function (checkbox) {
        if (checkbox.style.display !== "none") {
          var input = checkbox.getElementsByTagName("input")[0];
          input.checked = isChecked;
        }
      });

      // Ensure that only visible checkboxes are checked/unchecked
      updateSelectAllCheckboxState();
    });

    // Update the "Select All" checkbox state based on individual checkbox changes
    Array.from(checkboxes).forEach(function (checkbox) {
      var input = checkbox.getElementsByTagName("input")[0];

      input.addEventListener("change", function () {
        updateSelectAllCheckboxState();
      });
    });
  }

  setupRadioHandlers();
  // Setup handlers for both branch and gf_category lists
  setupCheckboxHandlers("branch-search", "branch-list");
  setupCheckboxHandlers("gf_category-search", "gf_category-list");
});
