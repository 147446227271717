// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/
//import * as Typeahead from "typeahead.js";
import Bloodhound from "bloodhound-js";

var global_clipboard;
var global_clipboard_type;

function whitespace_undescore(str) {
  return str ? str.split(/[\s_]+/) : [];
}

// constructs the suggestion engine
var spots = new Bloodhound({
  datumTokenizer: whitespace_undescore,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  // `states` is an array of state names defined in "The Basics"
  local: [],
});

// Read in data from html file (written vars from ruby)
if ($("#data-spots").length > 0) {
  $("#data-spots")
    .data("spots")
    .forEach((spot) => {
      spots.add(spot);
    });
}

let spot_types;
if ($("#data-spot-types").length > 0) {
  spot_types = $("#data-spot-types").data("spot-types");
}

$(function () {
  var spot_ids_to_destroy = [];

  $(".paste-all").on("click", function (e) {
    const target = e.target;
    const url = target.dataset.url;
    const attribute = target.dataset.attribute;
    if ($("#cancel-paste-button").is(":visible")) {
      let a = attribute;
      if (
        attribute.startsWith("image") ||
        attribute.startsWith("video") ||
        attribute.startsWith("welcome")
      )
        a += "_id";
      paste_col_value(global_clipboard, url, a);
    }
  });

  $("#spots .loading").css({
    cursor: "wait",
    display: "none",
    height: "100%",
    width: "100%",
    top: 0,
    position: "absolute",
  });
  $("#spots .loading").appendTo("#spots");

  $("#spot-search").typeahead(
    {
      hint: false,
      highlight: true,
      minLength: 1,
    },
    {
      name: "spots",
      source: spots,
    }
  );

  $("#spot-overview-search-from").submit(function (event) {
    event.preventDefault();

    var spot_name = $("#spot-search").val();
    var type = spot_types[spot_name];
    if (type) {
      window.location = window.location + "/" + type;
    } else {
      // Handle the case where the spot_name doesn't exist in spot_types
      console.error("Spot type not found for:", spot_name);
    }
    return false;
  });

  $("#cancel-paste-button").hide();

  $("#spots").on("click", "#cancel-paste-button", function (event) {
    $("table").addClass("copy");
    $("table").removeClass("paste");
    $("table").removeClass("text");
    $("table").removeClass("image");
    $("table").removeClass("video");
    $("table").removeClass("date");
    $(this).hide();

    event.preventDefault();
  });

  $("#spots").on("change", "input.destroy", function () {
    if (this.checked) {
      spot_ids_to_destroy.push($(this).val());
    } else {
      spot_ids_to_destroy.splice(
        $.inArray($(this).val(), spot_ids_to_destroy),
        1
      );
    }
  });

  $("#destroy_multiple").on("confirm:complete", function (e) {
    if (e.detail[0]) {
      var url = $(this).attr("href");
      $.ajax({
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            $('meta[name="csrf-token"]').attr("content")
          );
        },
        type: "POST",
        url: url,
        dataType: "script",
        data: { _method: "delete", spot_ids: spot_ids_to_destroy },
      })
        .done(function () {
          window.location.reload();
        })
        .fail(function () {
          alert("Failed to delete spots");
        });
      e.preventDefault();
    } else {
      // User cancelled.
    }
    e.preventDefault();
    return false;
  });

  $("#spot_price_region_filter").change(function (event) {
    filter_table();
  });

  $("#spot_category_filter").change(function (event) {
    filter_table();
  });

  $("#spot_valid_from_filter").change(function (event) {
    filter_table();
  });

  $("#spot_valid_to_filter").change(function (event) {
    filter_table();
  });

  $("#spots").on("click", ".preview_link", function (event) {
    var url = $(this).attr("href");

    window.open(
      url,
      "preview",
      "toolbar=no,scrollbars=no,resizable=no,top=500,left=500,width=290,height=520"
    );

    event.preventDefault();
  });

  $("#spots").on("click", ".copy-overlay", function (event) {
    var type = $(this).data("type");
    var value = $(this).data("value");

    copy_value(type, value);

    event.preventDefault();
  });

  $("#spots").on("click", ".paste-overlay", function (event) {
    var url = $(this).data("url");
    var attribute = $(this).data("attribute");

    paste_value(global_clipboard, url, attribute);

    event.preventDefault();
  });

  $("#edit_spot").on("change", ".selectpicker.image", update_preview);
  $("#edit_spot").on("change", ".selectpicker.video", update_preview);
  $("#edit_spot").on("change", ".selectpicker.welcome", update_preview);

  $(".sortable").click(function () {
    var table = $(this).closest("table");
    var columnIndex = $(this).index();

    var rows = table.find("tbody tr").get();

    var sortOrder = $(this).data("sort-order") || "asc";
    $(this).data("sort-order", sortOrder);

    $(this).hasClass("number") &&
      rows.sort((a, b) => sortNumber(a, b, columnIndex, sortOrder));

    $(this).hasClass("date") &&
      rows.sort((a, b) => sortDate(a, b, columnIndex, sortOrder));

    $(this).hasClass("string") &&
      rows.sort((a, b) => sortString(a, b, columnIndex, sortOrder));

    table.find("tbody").empty().append(rows);
    displaySortingDirection($(this)[0]);
    $(this).data("sort-order", sortOrder === "asc" ? "desc" : "asc");
  });

  $("#branchSearch").on("input", function () {
    filterEdgarTable();
  });

  $("#durationMin").on("input", function () {
    filterEdgarTable();
  });

  $("#durationMax").on("input", function () {
    filterEdgarTable();
  });
});

function filterEdgarTable() {
  let query = $("#branchSearch").val();
  query = query.trim();
  query = query.toLowerCase();

  let minDur = parseInt($("#durationMin").val());
  let maxDur = parseInt($("#durationMax").val());

  $("table tbody tr").each(function () {
    let branchVisible = false;
    let durationMinVisible;
    let durationMaxVisible;

    // Branch
    const branchFields = $(this).find(".branch");
    branchFields.each((i) => {
      let branchData = $(branchFields[i]).data("branch");
      if (!Array.isArray(branchData)) {
        branchData = [branchData];
      }
      branchVisible = branchData.some((branch) => {
        const queryData = {
          branch: branch,
        };
        return Object.values(queryData).some((value) => {
          return value.toString().toLowerCase().includes(query);
        });
      });
    });

    // Duration
    let duration = parseInt($(this).find(".duration").text().trim());
    durationMinVisible = isNaN(minDur) || duration >= minDur;
    durationMaxVisible = isNaN(maxDur) || duration <= maxDur;

    if (branchVisible && durationMinVisible && durationMaxVisible) {
      $(this).show();
    } else {
      $(this).hide();
    }
  });
}

function displaySortingDirection(target) {
  let sortableFields = $(".sortable");
  sortableFields.each((i) => {
    if ($(sortableFields[i]).data("sort-order")) {
      let c = $("#sortDirection");
      c.remove();
    }
  });
  let s = document.createElement("span");
  s.id = "sortDirection";
  s.classList.add("ms-1");
  s.textContent = $(target).data("sort-order") === "asc" ? "\u2193" : "\u2191";
  target.appendChild(s);
}

function sortDate(a, b, columnIndex, sortOrder) {
  console.log("Date");
  var aValue = $(a).find("td").eq(columnIndex).text();
  var bValue = $(b).find("td").eq(columnIndex).text();

  var aDate = parseDate(aValue.trim());
  var bDate = parseDate(bValue.trim());

  let r;
  if (sortOrder === "asc") {
    r = aDate.getTime() - bDate.getTime();
  } else {
    r = bDate.getTime() - aDate.getTime();
  }
  return r;
}

function sortNumber(a, b, columnIndex, sortOrder) {
  console.log("Number");
  var aValue = $(a).find("td").eq(columnIndex).text();
  var bValue = $(b).find("td").eq(columnIndex).text();

  let r;
  if (sortOrder === "asc") {
    r = aValue - bValue;
  } else {
    r = bValue - aValue;
  }
  return r;
}

function sortString(a, b, columnIndex, sortOrder) {
  console.log("String");
  var aValue = $(a).find("td").eq(columnIndex).text().toLowerCase();
  var bValue = $(b).find("td").eq(columnIndex).text().toLowerCase();

  let r = 0;
  if (aValue < bValue) {
    r = -1;
  }
  if (aValue > bValue) {
    r = 1;
  }

  if (sortOrder !== "asc") {
    r = r * -1;
  }

  return r;
}

function parseDate(dateString) {
  var parts = dateString.split(" ");
  var datePart = parts[0];
  var timePart = parts[1];

  var dateParts = datePart.split(".");
  var day = parseInt(dateParts[0]);
  var month = parseInt(dateParts[1]) - 1;
  var year = 2000 + parseInt(dateParts[2]);

  var timeParts = timePart.split(":");
  var hours = parseInt(timeParts[0]);
  var minutes = parseInt(timeParts[1]);

  return new Date(year, month, day, hours, minutes);
}

function getEdgarAttachments() {
  return $(".attachmentTransfer.edgarMedium").data("attachments");
}

function getAttachments() {
  return $(".attachmentTransfer.medium").data("attachments");
}

function update_preview(e) {
  e.preventDefault();
  var select = $(this);
  var name = $(this).data("attribute");
  var attachments =
    name === "edgar_medium" ? getEdgarAttachments() : getAttachments();

  if ($(select).val() == "" || $(select).val() == null) {
    $("#" + name + "_preview").attr(
      "src",
      "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
    );
  } else {
    $("#" + name + "_preview").attr("src", attachments[$(select).val()]);
  }
}

function copy_value(type, value) {
  global_clipboard = value;
  global_clipboard_type = type;
  $("table").removeClass("copy");
  $("table").addClass("paste");
  $("table").addClass(type);

  console.log(type);
  $("#cancel-paste-button").show();
}

function paste_value(value, url, attribute) {
  console.log("saving " + value + " for attribute " + attribute + " at " + url);

  var payloadData = {};
  payloadData[attribute] = value;
  $.ajax({
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        $('meta[name="csrf-token"]').attr("content")
      );
    },
    type: "PUT",
    url: url,
    data: { spot: payloadData },
    dataType: "json",
    error: function (jqXHR, textStatus, errorThrown) {
      alert(textStatus + "\n" + errorThrown);
    },
    complete: function (jqXHR, textStatus) {
      if (textStatus === "success") {
        window.location.reload();
      }
    },
  });
}

function paste_col_value(value, url, attribute) {
  console.log("saving " + value + " for attribute " + attribute + " at " + url);

  var visibleIDs = $("tr.editable")
    .filter(":visible")
    .map(function () {
      return $(this).data("id");
    })
    .get();

  var payloadData = {};
  payloadData[attribute] = value;
  $.ajax({
    type: "POST",
    url: url,
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        $('meta[name="csrf-token"]').attr("content")
      );
    },
    data: { _method: "PATCH", spot: payloadData, ids: visibleIDs.join(",") },
    dataType: "script",
    error: function (jqXHR, textStatus, errorThrown) {
      alert(textStatus + "\n" + errorThrown);
    },
    complete: function () {
      window.location.reload();
    },
  });
}

function filter_table() {
  var price_region = $("#spot_price_region_filter").val();
  var category = $("#spot_category_filter").val();
  var valid_from = $("#spot_valid_from_filter").val();
  var valid_to = $("#spot_valid_to_filter").val();

  $("tr.editable").each(function (index) {
    var price_region_show =
      price_region == "" || $(this).data("price-region") == price_region;
    var category_show = category == "" || $(this).data("category") == category;
    var valid_from_show =
      valid_from == "" || $(this).data("valid-from") == valid_from;
    var valid_to_show = valid_to == "" || $(this).data("valid-to") == valid_to;
    if (
      (price_region_show || category_show) &&
      valid_from_show &&
      valid_to_show
    ) {
      $(this).show();
    } else {
      $(this).hide();
    }
  });
}
